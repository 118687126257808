import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import * as yup from 'yup';

import Button, {ButtonSizes, ButtonTypes} from './ButtonNew';
import {FacebookButton, GoogleButton, LinkedInButton} from './SocialButton';
import {TextInput} from './Forms';
import UniversitySSOModal from './UniversitySSOModal';
import {useAnalytics} from '../hooks';

const passwordSchema = yup.object().shape({
  password: yup.string()
    .required('Must provide a password')
    .min(8, 'Must be at least 8 characters long'),
});

const errorMessageMap = {
  sso_authorization: "Sorry, you don't have Exponent access from your school. Please reach out to your school administrator for assistance or try using a different login method. If you believe you're seeing this in error, let us know at support@tryexponent.com.",
};

export default function LoginForm(props) {
  const {tracker} = useAnalytics();
  const [incorrect, setIncorrect] = useState(false);
  const [existing, setExisting] = useState(false);
  const [email, setEmail] = useState(null);
  const [returnPath, setReturnPath] = useState(null);
  const [error, setError] = useState(null);
  const [universityModalOpen, setUniversityModalOpen] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    const windowParams = new URLSearchParams(window.location.search);
    if (windowParams.has('incorrect')) {
      setIncorrect(true);
    }
    if (windowParams.has('existing')) {
      setExisting(true);
    }
    if (windowParams.has('email')) {
      setEmail(windowParams.get('email'));
    }
    if (windowParams.has('returnPath')) {
      setReturnPath(windowParams.get('returnPath'));
    }
    if (windowParams.has('error')) {
      const err = windowParams.get('error');
      setError(errorMessageMap[err] || err);
    }
  }, []);

  const openUniversityModal = () => {
    tracker.loginClickSso({type: 'university'});
    setUniversityModalOpen(true);
  };

  const closeUniversityModal = () => {
    setUniversityModalOpen(false);
  };

  const {
    isSignup,
    isFinishSignup,
    src,
    purchase,
    csrfToken,
    onClickSSO,
  } = props;

  const showSocial = !isFinishSignup;

  let submitText = 'Log in';
  if (isSignup) {
    submitText = 'Get started';
  } else if (isFinishSignup) {
    submitText = 'Set password';
  } else if (purchase) {
    submitText = 'Continue';
  }

  const handleSubmit = () => {
    formRef.current.submit();
  };

  return (
    <div>
      <UniversitySSOModal
        isOpen={universityModalOpen}
        onClose={closeUniversityModal}
      />
      {showSocial && (
        <>
          <div className="flex flex-col space-y-2">
            <GoogleButton
              onClick={() => onClickSSO('google')}
              returnPath={returnPath}
            >
              {isSignup ? 'Sign up with Google' : 'Log in with Google'}
            </GoogleButton>
            <FacebookButton
              onClick={() => onClickSSO('facebook')}
              returnPath={returnPath}
            >
              {isSignup ? 'Sign up with Facebook' : 'Log in with Facebook'}
            </FacebookButton>
            <LinkedInButton
              onClick={() => onClickSSO('linkedin')}
              returnPath={returnPath}
            >
              {isSignup ? 'Sign up with LinkedIn' : 'Log in with LinkedIn'}
            </LinkedInButton>
            <Button
              buttonType={ButtonTypes.SECONDARY}
              size={ButtonSizes.LARGE}
              onClick={openUniversityModal}
            >
              Log in with university
            </Button>
          </div>
          <div className="LoginForm__divider">
            <span className="LoginForm__divider-text">or</span>
          </div>
        </>
      )}
      <Formik
        enableReinitialize // So that email is populated
        initialValues={{email, password: ''}}
        validationSchema={isSignup ? passwordSchema : null}
        onSubmit={handleSubmit}
      >
        <Form ref={formRef} method="post" className="mb-4 flex flex-col">
          <input type="hidden" name="_csrf" value={csrfToken} />
          <input type="hidden" name="src" value={src} />
          {incorrect && !isSignup && (
            <span className="mb-4 px-2 py-1 bg-red-400 text-white text-center rounded-lg">
              Sorry, wrong email or password
            </span>
          )}
          {existing && isSignup && (
            <span className="mb-4 px-2 py-1 bg-red-400 text-white text-center rounded-lg">
              Sorry, this email is already in use
            </span>
          )}
          {error && (
            <span className="mb-4 px-2 py-1 bg-red-400 text-white text-center rounded-lg">
              {error}
            </span>
          )}
          <div className="">
            <TextInput
              name="email"
              label="Email address"
              type="email"
              placeholder="Your email address"
              required
              readOnly={!!email}
            />
            {isFinishSignup && (
              <input
                type="hidden"
                value=""
                name="email"
              />
            )}
            <TextInput
              name="password"
              label="Password"
              type="password"
              placeholder={(isFinishSignup || isSignup) ? 'Choose a password' : 'Password'}
              required
            />
            <div className="">
              <Button className="my-3 w-full" type="submit" size={ButtonSizes.LARGE}>
                {submitText}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

LoginForm.propTypes = {
  isSignup: PropTypes.bool,
  isFinishSignup: PropTypes.bool,
  src: PropTypes.string,
  onClickSSO: PropTypes.func,
  purchase: PropTypes.bool,
};

LoginForm.defaultProps = {
  isSignup: false,
  isFinishSignup: false,
  src: null,
  purchase: false,
  onClickSSO: () => { },
};
