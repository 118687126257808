export const universities = [
  {
    name: 'Stanford GSB',
    image: '/images/schools/gsb.png',
    link: '/login/saml/stanford',
  },
  {
    name: 'Chicago Booth',
    image: '/images/schools/chicago_booth.png',
    link: '/login/saml/uchicago',
  },
  {
    name: 'Georgia Tech',
    image: '/images/schools/gatech.png',
    link: '/login/cas/gatech',
  },
  {
    name: 'Yale SOM',
    image: '/images/schools/yale_som.png',
    link: '/login/cas/yale',
  },
  {
    name: 'Duke Fuqua',
    image: '/images/schools/duke_fuqua.jpg',
    link: '/login/saml/duke-fuqua',
  },
  {
    name: 'MIT Sloan, SDM, IDM',
    image: '/images/schools/mit_sloan.png',
    link: '/login/saml/mit',
  },
  {
    name: 'Brown University',
    image: '/images/schools/brown.png',
    link: '/login/saml/brown-university',
  },
  {
    name: 'Harvard Business School HBS',
    image: '/images/schools/hbs.png',
    link: '/login/saml/hbs',
  },
  {
    name: 'Columbia GSB',
    image: '/images/schools/columbia_gsb.png',
    link: '/login/saml/columbia',
  },
  {
    name: 'Cornell University',
    image: '/images/schools/cornell.png',
    link: '/login/saml/cornell',
  },
  {
    name: 'UMich Ross',
    image: '/images/schools/ross.png',
    link: '/login/saml/umich',
  },
  {
    name: 'NYU Stern',
    image: '/images/schools/nyu_stern.png',
    link: '/login/saml/nyu',
  },
  // {
  //   name: 'Chapman University',
  //   image: '/images/schools/chapman.jpg',
  //   link: '/login/saml/chapman',
  // },
  {
    name: 'Dartmouth Tuck',
    image: '/images/schools/dartmouth_tuck.png',
    link: '/login/saml/dartmouth',
  },
  {
    name: 'John Hopkins Carey',
    image: '/images/schools/john_hopkins_carey.png',
    link: '/login/saml/jhu',
  },
  {
    name: 'Georgetown McDonough',
    image: '/images/schools/georgetown_mcdonough.png',
    link: '/login/saml/georgetown',
  },
  {
    name: 'Emory GBS',
    image: '/images/schools/emory_gbs.png',
    link: '/login/saml/emory-gbs',
  },
  {
    name: 'Esade',
    image: '/images/schools/esade.png',
    link: '/login/saml/esade',
  },
  {
    name: 'University of Pennsylvania',
    link: '/login/saml/wharton',
  },
  {
    name: 'Wharton School (University of Pennsylvania)',
    link: '/login/saml/wharton',
  },
  {
    name: 'Rochester Simon',
    image: '/images/schools/rochester_simon.png',
    link: '/login/saml/simon',
  },
  {
    name: 'UVA Darden',
    image: '/images/schools/uva_darden.jpg',
    link: '/login/saml/darden',
  },
  {
    name: 'Carnegie Mellon Tepper',
    image: '/images/schools/tepper.png',
    link: '/login/saml/cmu',
  },
  {
    name: 'Columbia Engineering',
    image: '/images/schools/columbia_engineering.png',
    link: '/login/saml/columbia',
  },
  {
    name: 'Duke Pratt',
    image: '/images/schools/duke_pratt.png',
    link: '/login/saml/duke-fuqua',
  },
  {
    name: 'Northwestern',
    image: '/images/schools/northwestern.png',
    link: '/login/saml/northwestern',
  },
  {
    name: 'University of Texas Austin McCombs (UT Austin)',
    image: '/images/schools/utexas_mccombs.png',
    link: '/login/saml/utexas',
  },
  {
    name: 'University of Texas Austin (UT Austin)',
    image: '/images/schools/utexas.png',
    link: '/login/saml/utexas',
  },
  {
    name: 'University of Maryland',
    image: '/images/schools/umd_smith.png',
    link: '/login/saml/umd',
  },
  {
    name: 'UCLA Anderson',
    image: '/images/schools/ucla_anderson.png',
    link: '/login/saml/ucla',
  },
  {
    name: 'Grinnell College',
    image: '/images/schools/grinnell.png',
    link: '/login/saml/grinnellcollege',
  },
  {
    name: 'Rice University Jones School of Business',
    image: '/images/schools/rice.png',
    link: '/login/saml/rice',
  },
  {
    name: 'Ivey Business School',
    image: '/images/schools/ivey.png',
    link: '/login/saml/ivey',
  },
];

export default universities;
