import React, {useState, useEffect} from 'react';

import Link from '../components/Link';
import DocumentHeader from '../components/DocumentHeader';
import LoginForm from '../components/LoginForm';
import {useAnalytics, useAppContext} from '../hooks';

const LoginPage = ({isSignup, isFinishSignup}) => {
  const {tracker} = useAnalytics();
  const {csrfToken} = useAppContext();
  const [src, setSrc] = useState('source_signup');
  const [purchase, setPurchase] = useState(false);
  const [redirectPath, setRedirectPath] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('src')) {
      setSrc(params.get('src'));
    }
    if (params.has('purchase')) {
      const paramsPurchase = params.get('purchase');
      setPurchase(paramsPurchase);
    }

    // Set up url to take user to along with the redirect if needed
    setRedirectPath(window.location.search);
  }, []);

  useEffect(() => {
    if (isSignup) {
      tracker.signupView();
    } else {
      tracker.loginView();
    }
  }, [isSignup]);

  const onClickSSO = (type) => {
    tracker.loginClickSso({type});
  };

  let pageTitle = 'Log in to Exponent';
  if (isSignup) {
    pageTitle = 'Sign up for Exponent';
  } else if (isFinishSignup) {
    pageTitle = 'Set your password';
  } else if (purchase) {
    pageTitle = '🎉 You\'re in!';
  }

  let subtext;
  if (isSignup) {
    subtext = (
      <p>
        Already use Exponent?
        {' '}
        <Link className="text-indigo font-semibold" href={`/login${redirectPath}`}>
          Log in here
        </Link>
        .
      </p>
    );
  } else if (!purchase) {
    subtext = (
      <p>
        Don’t have an account?
        {' '}
        <Link className="text-indigo font-semibold" href={`/signup${redirectPath}`}>
          Sign up here
        </Link>
        .
      </p>
    );
  }

  return (
    <div className="bg-gray-50 sm:container sm:pt-8 sm:mx-auto mb-8">
      <DocumentHeader title={pageTitle} />
      <div className="bg-white mx-auto sm:border border-gray-200 sm:shadow-lg px-10 py-6 pt-8 w-full sm:max-w-lg rounded-lg">
        <div className="">
          <h1 className="text-2xl text-center font-bold text-black mb-5">
            {pageTitle}
          </h1>
          {purchase && <p className="-mt-3 mb-4">Your purchase was successful, please log in to continue!</p>}
        </div>
        <LoginForm
          src={src}
          isSignup={isSignup}
          isFinishSignup={isFinishSignup}
          onClickSSO={onClickSSO}
          purchase={purchase}
          csrfToken={csrfToken}
        />
        <div className="text-center text-gray-600 font-medium space-y-1">
          {!isFinishSignup && subtext}
          {!isFinishSignup && !isSignup && (
          <p>
            Forgot your password?
            {' '}
            <Link className="text-indigo font-semibold" href="/reset">
              Reset it here
            </Link>
            .
          </p>
          )}
          {!isFinishSignup && isSignup && (
          <p>
            By creating an account, you agree to our&nbsp;
            <Link className="text-indigo font-semibold" href="/tos">terms of service</Link>
            &nbsp;and&nbsp;
            <Link className="text-indigo font-semibold" href="/privacy">privacy policy.</Link>
          </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
