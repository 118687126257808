/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import * as cx from 'classnames';

import Button, {ButtonTypes, ButtonSizes} from './ButtonNew';
import GoogleIcon from '../../css/images/companies/google.png';
import FacebookIcon from '../../css/images/companies/facebook.png';
import LinkedInIcon from '../../css/images/companies/linkedin.png';

export const FacebookButton = ({className, ...props}) => {
  const trackSSOLogin = () => {
    if (props.onClick) props.onClick('facebook');
  };

  return (
    <Button
      buttonType={ButtonTypes.SECONDARY}
      size={ButtonSizes.LARGE}
      onClick={trackSSOLogin}
      href={`/login/facebook${
        props.returnPath ? `?returnPath=${encodeURIComponent(props.returnPath)}` : ''
      }`}
      className={className}
      {...props}
    >
      <img alt="Facebook Logo" className="mr-2 w-5 h-5" src={FacebookIcon.src} />
      <span>
        {props.children || 'Facebook'}
      </span>
    </Button>
  );
};

export const GoogleButton = ({className, ...props}) => {
  const trackSSOLogin = () => {
    if (props.onClick) props.onClick('google');
  };

  return (
    <Button
      buttonType={ButtonTypes.SECONDARY}
      size={ButtonSizes.LARGE}
      onClick={trackSSOLogin}
      href={`/login/google${
        props.returnPath ? `?returnPath=${encodeURIComponent(props.returnPath)}` : ''
      }`}
      className={cx('flex items-center justify-center', className)}
      {...props}
    >
      <img alt="Google Logo" className="mr-2 w-5 h-5" src={GoogleIcon.src} />
      <span>
        {props.children || 'Google'}
      </span>
    </Button>
  );
};

export const LinkedInButton = ({className, ...props}) => {
  const trackSSOLogin = () => {
    if (props.onClick) props.onClick('linkedin');
  };

  return (
    <Button
      buttonType={ButtonTypes.SECONDARY}
      size={ButtonSizes.LARGE}
      onClick={trackSSOLogin}
      href={`/login/linkedin${
        props.returnPath ? `?returnPath=${encodeURIComponent(props.returnPath)}` : ''
      }`}
      className={cx('flex items-center justify-center', className)}
      {...props}
    >
      <img alt="LinkedIn Logo" className="mr-2 w-5 h-5" src={LinkedInIcon.src} />
      <span>
        {props.children || 'LinkedIn'}
      </span>
    </Button>
  );
};

const buttonProps = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  returnPath: PropTypes.string,
  children: PropTypes.node,
};

const defaultButtonProps = {
  className: null,
  onClick: null,
  returnPath: null,
  children: null,
};

FacebookButton.propTypes = buttonProps;
FacebookButton.defaultProps = defaultButtonProps;

GoogleButton.propTypes = buttonProps;
GoogleButton.defaultProps = defaultButtonProps;

LinkedInButton.propTypes = buttonProps;
LinkedInButton.defaultProps = defaultButtonProps;
