import React, {useState} from 'react';

import Modal from './ModalNew';
import {StyledTextInput as TextInput} from './Forms';
import {universities} from '../constants/universities';

const UniversitySSOModal = ({isOpen, onClose}) => {
  const [filter, setFilter] = useState(null);

  const filteredResults = universities.filter((u) => {
    if (!filter) return true;
    return u.name.toLowerCase().includes(filter.toLowerCase().trim());
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalClassName="sm:max-w-2xl sm:h-5/6"
      centerVertically={false}
      showCloseButton
    >
      <div className="bg-white w-full">
        <div className="mx-auto flex flex-col w-full py-8 px-4 sm:px-6 lg:py-10 lg:px-8">
          <p className="text-center text-xl font-bold text-gray-800">
            Log in with your exclusive university access
          </p>
          <p className="text-center text-md text-gray-600 pt-2 py-5">
            Want to see your university listed here?
            {' '}
            <a className="text-indigo-600 underline" href="https://airtable.com/shri4wDQfhnJeHEgf">Fill out our university interest form.</a>
          </p>
          <div className="px-1">
            <TextInput
              placeholder="Search for your university"
              value={filter}
              className="w-full mt-2"
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <div className="mt-6 grid grid-cols-2 gap-1 md:grid-cols-3 lg:mt-4 items-stretch">
            {filteredResults.map((u) => (
              <a href={u.link} className="h-32 w-full py-8 px-8 flex justify-center items-center bg-gray-50 hover:bg-gray-100 rounded-lg">
                {u.image ? (
                  <img className="max-h-20 mix-blend-multiply" src={u.image} alt={u.name} />
                ) : (
                  <span className="text-gray-700 font-medium leading-tight text-center text-base">{u.name}</span>
                )}
              </a>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UniversitySSOModal;
