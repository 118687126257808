import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react-v2/24/solid';
import cx from 'classnames';

export default function ModalNew({
  header,
  isOpen,
  modalClassName,
  showCloseButton,
  centerVertically = true,
  children,
  onClose = () => {},
}) {
  return (
    <Transition.Root appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className={cx('flex min-h-full items-end justify-center p-4 text-center sm:p-0', centerVertically ? 'sm:items-center' : 'sm:items-start')}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={cx('relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full max-w-lg p-6 sm:my-8', modalClassName)}>
                {showCloseButton && (
                  <button
                    type="button"
                    className="absolute top-2 right-2 p-2 rounded-lg text-gray-400 hover:text-gray-600 hover:bg-gray-50 transition duration-150"
                    onClick={onClose}
                  >
                    <XMarkIcon className="h-6 w-6" />
                    <span className="sr-only">Close</span>
                  </button>
                )}
                {header && <Dialog.Title className="mb-4 flex justify-center text-lg font-semibold text-gray-900 tracking-tight">{header}</Dialog.Title>}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
